<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="mdPreview"
    tabindex="-1"
    aria-labelledby="mdPreviewLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl modal-fullscreen-md-down">
      <div style="height: 100%" class="modal-content">
        <AlertBrowserFBugs />
        <div class="d-flex justify-content-end p-2" v-if="ismobile">
          <div class="pe-2 ps-2 click" @click="btnPrintClick">
            <svg
              width="20px"
              viewBox="0 0 24 24"
              preserveAspectRatio="xMidYMid meet"
              focusable="false"
              role="none"
              style="
                pointer-events: none;
                display: block;
                width: 100%;
                height: 100%;
              "
            >
              <g>
                <path
                  d="M19 8H5c-1.66 0-3 1.34-3 3v6h4v4h12v-4h4v-6c0-1.66-1.34-3-3-3zm-3 11H8v-5h8v5zm3-7c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm-1-9H6v4h12V3z"
                ></path>
              </g>
            </svg>
          </div>
          <div @click="btnDwonloadClick" class="pe-2 ps-2 click">
            <svg
              width="20px"
              viewBox="0 0 24 24"
              preserveAspectRatio="xMidYMid meet"
              focusable="false"
              role="none"
              style="
                pointer-events: none;
                display: block;
                width: 100%;
                height: 100%;
              "
            >
              <g>
                <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path>
              </g>
            </svg>
          </div>
          <div class="me-2 ms-2">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
        </div>
        <div style="height: 100%" v-html="htmlView"></div>
      </div>
    </div>
  </div>
</template>

<script>
import AlertBrowserFBugs from "@/components/alert/AlertBrowserFBugs";
export default {
  name: "ViewCV",
  data() {
    return {
      ismobile: false,
    };
  },
  props: {
    urlPreview: {
      default: "",
    },
  },
  components: {
    AlertBrowserFBugs,
  },
  computed: {
    htmlView: function() {
      if (this.urlPreview == "") return "";
      let timeTk = new Date().getTime();
      let url = this.urlPreview;
      if (this.ismobile)
        url = "https://themycv.com/web/viewer.html?file=" + url;

      return (
        ' <iframe id="myEmbledView" src="' +
        url +
        '" frameborder="0" width="100%" height="100%" z=' +
        timeTk +
        "> </iframe>"
      );
    },
  },
  methods: {
    btnDwonloadClick() {
      let iframe = document.getElementById("myEmbledView");
      iframe.contentWindow.document.getElementById("download").click();
    },
    btnPrintClick() {
      let iframe = document.getElementById("myEmbledView");
      iframe.contentWindow.document.getElementById("print").click();
    },
  },

  mounted() {
    if (window.innerWidth < 760) this.ismobile = true;
    else this.ismobile = false;
  },
};
</script>

<style scoped>
.modal-dialog {
  height: 94%;
}
@media (max-width: 720px) {
  .modal-dialog {
    height: 100%;
  }
}
</style>
